import React from 'react'
import ReactMarkdown from 'react-markdown'

import Layout from '../../layout'
import SEO from '../../utils/seo'

import { GalleryCatalog } from '../../components/individualCatalog'
import {
    IntroProduct,
    ResumeProduct,
    GalleryProduct,
    ContentProduct,
} from '../../components/Modules/Solutions/styles'
import Divider from '../../components/Divider'

export const query = graphql`
    query ProductQuery($id: Int!) {
        strapiProdutos(strapiId: { eq: $id }) {
            strapiId
            name
            resume
            description
            image {
                publicURL
            }
            gallery {
                id
                url
            }
        }
    }
`

const SolutionPage = ({ data }) => {
    const { name, resume, description, image, gallery } = data?.strapiProdutos

    let title = name
    const transformTitleInArray = title?.split(' ')
    const lastWordInTitle = transformTitleInArray?.pop()
    title = transformTitleInArray?.join(' ')

    return (
        <Layout>
            <SEO title={title} />

            <IntroProduct>
                <div className="content-product-wrapper">
                    <h1 className="title-product">
                        {title} <b>{lastWordInTitle}</b>
                    </h1>

                    <Divider size={4} orientation="horizontal" />

                    <div className="indicated-sectors">
                        <div className="text-indicated-sectors">
                            <p>Indicado para:</p>
                        </div>

                        <div className="icons-indicated-sectors">
                            <img
                                src="https://www.sunnyvale.com.br/wp-content/uploads/2020/10/003-cosmetics-copy.svg"
                                alt="Tabaco"
                                className="icons-img"
                            />
                        </div>
                    </div>
                </div>

                <div className="image-product-wrapper">
                    <img src={image?.publicURL} alt="Nome do produto" className="image-product" />
                </div>
            </IntroProduct>

            <Divider size={1} orientation="horizontal" />

            <ResumeProduct>
                <div className="content-wrapper">
                    <div className="title-content">
                        <h3>Sobre o produto</h3>
                    </div>

                    <div className="description-content">
                        <ReactMarkdown className="contentCatalog" source={resume} />
                    </div>
                </div>
            </ResumeProduct>

            <Divider size={1} orientation="horizontal" />

            <ContentProduct>
                <ReactMarkdown className="contentCatalog" source={description} />
            </ContentProduct>

            <Divider size={1} orientation="horizontal" />

            <GalleryProduct>
                <h3 className="title-gallery">Galeria de fotos</h3>

                <Divider size={5} orientation="horizontal" />

                <GalleryCatalog
                    data={gallery.map((image) => ({
                        id: `${name} - ${image.id}`,
                        name: `${name} - ${image.id}`,
                        url: image.url,
                    }))}
                />
            </GalleryProduct>

            <Divider size={1} orientation="horizontal" />
        </Layout>
    )
}

export default SolutionPage
